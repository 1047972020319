<template>
  <div class="main noScroll">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            key="parent"
            @on-click="onClickTabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
        <div class="flex a-center"
             slot="extra">
          <Icon custom="i-icon icon-tishi1"
                size="16"
                color="#FF9B77"
                class="m-r-5"></Icon>
          今日数据会在次日凌晨1点更新到本系统
        </div>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <Select v-model="selectType"
                    placeholder="查询分类"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in selectTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <!-- <Select v-model="carType"
                    placeholder="车辆类型"
                    class="m-r-10"
                    style="width:160px"
                    v-if="currentTabIndex===1">
              <Option v-for="item in typeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select> -->
            <!-- <MyTreeSelect :value="carNumber"
                          placeholder="车牌号"
                          :data="carTreeArr"
                          search
                          checked
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          @onCheckChange="onChangeName">
            </MyTreeSelect> -->
            <!-- <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          class="m-r-10"
                          style="width:160px"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect> -->
            <template v-if="currentTabIndex===1">
              <Select v-model="remoteName"
                      filterable
                      clearable
                      :remote-method="remoteNameMethod"
                      :loading="remoteNameLoading"
                      placeholder="请输入姓名"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteName">
                <Option v-for="(option, index) in remoteNameList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <Select v-model="personViolateType"
                      clearable
                      placeholder="违规类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in personViolateTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===0">
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <Select v-model="carViolateType"
                      clearable
                      placeholder="违规类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in carViolateTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <!-- <template v-if="currentTabIndex===1">
              <div class="flex a-center m-r-10">
                <span>停留超过：</span>
                <Input v-model="stayMinute"
                       placeholder=""
                       class="m-r-5"
                       style="width:100px" />
                <span>分钟</span>
              </div>
            </template> -->
            <!-- <template v-if="currentTabIndex===2">
              <div class="flex a-center m-r-10">
                <span>停留超过：</span>
                <Input v-model="parkingMinute"
                       placeholder=""
                       class="m-r-5"
                       style="width:100px" />
                <span>分钟</span>
              </div>
            </template> -->
            <DatePicker :value="date"
                        type="daterange"
                        ref="formDate"
                        :clearable="false"
                        :editable="false"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        :options="dateOptions"
                        @on-change="onChangeDate"
                        style="width:260px"
                        v-show="selectType===1"></DatePicker>
            <Monthrange :itemStyle="{width:'160px','margin-right':'10px'}"
                        :dateMonthrange="dateMonthrange"
                        @onChange="onChangeMonthrange"
                        v-show="selectType===2"></Monthrange>
            <!-- <DatePicker :value="dateMonth"
                        type="month"
                        :clearable="false"
                        :editable="false"
                        placeholder="选择月份"
                        @on-change="onChangeDate"
                        :options="dateOptions"
                        style="width:160px"
                        v-if="selectType===2"></DatePicker> -->
          </div>
          <div class="flex">
            <template v-if="selectType===1">
              <Button type="primary"
                      class="m-l-10"
                      v-hasPermi="['/admin/violationReport/query']"
                      :ghost="buttonIndex == 2 ? false : true"
                      @click.stop="onClickNearlyThreeDay">近三天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-hasPermi="['/admin/violationReport/query']"
                      :ghost="buttonIndex == 0 ? false : true"
                      @click.stop="onClickNearlySevenDay">近七天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-hasPermi="['/admin/violationReport/query']"
                      :ghost="buttonIndex == 1 ? false : true"
                      @click.stop="onClickCurrentMonth">本月</Button>
            </template>
            <template v-if="selectType===2">
              <Button type="primary"
                      class="m-l-10"
                      v-hasPermi="['/admin/violationReport/query']"
                      :ghost="buttonIndex == 3 ? false : true"
                      @click.stop="onClickPreMonthDay(3)">近三月</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-hasPermi="['/admin/violationReport/query']"
                      :ghost="buttonIndex == 4 ? false : true"
                      @click.stop="onClickPreMonthDay(6)">近六月</Button>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-hasPermi="['/admin/violationReport/query']"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-hasPermi="['/admin/violationReport/query']"
                    @click.stop="onResetSearchData">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <div class="section">
          <Tabs v-model="currentRunEchartsTabIndex"
                class="tabs"
                key="childEcharts1">
            <TabPane v-for="item in runEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"
                   :data="carViolationsEchartsData"
                   v-show="carViolationsEchartsData.length"></Echarts>
          <div class="noData"
               v-show="carViolationsEchartsData.length===0">
            <img src="@/assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
      </template>
      <template v-if="currentTabIndex===1">
        <div class="section">
          <Tabs v-model="currentStayEchartsTabIndex"
                class="tabs"
                key="childEcharts2">
            <TabPane v-for="item in stayEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"
                   :data="personViolationsEchartsData"
                   v-show="personViolationsEchartsData.length"></Echarts>
          <div class="noData"
               v-show="personViolationsEchartsData.length===0">
            <img src="@/assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
      </template>
      <template v-if="currentTabIndex===2">
        <div class="section">
          <Tabs v-model="currentParkEchartsTabIndex"
                class="tabs"
                key="childEcharts3">
            <TabPane v-for="item in parkEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"
                   :data="parkingEchartsData"
                   v-show="parkingEchartsData.length"></Echarts>
          <div class="noData"
               v-show="parkingEchartsData.length===0">
            <img src="@/assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
      </template>
      <div class="section">
        <div class="flex a-center j-between">
          <template v-if="currentTabIndex===0">
            <Tabs v-model="currentRunTabIndex"
                  class="tabs"
                  key="child1">
              <TabPane v-for="item in runTabArr"
                       :key="item.name"
                       :label="item.name"
                       :disabled="currentRunTabIndex==0"></TabPane>
            </Tabs>
          </template>
          <template v-if="currentTabIndex===1">
            <Tabs v-model="currentStayTabIndex"
                  class="tabs"
                  key="child2">
              <TabPane v-for="item in stayTabArr"
                       :key="item.name"
                       :label="item.name"
                       :disabled="currentStayTabIndex==0"></TabPane>
            </Tabs>
          </template>
          <!-- <template v-if="currentTabIndex===2">
            <Tabs v-model="currentParkTabIndex"
                  class="tabs"
                  key="child3">
              <TabPane v-for="item in parkTabArr"
                       :key="item.name"
                       :label="item.name"></TabPane>
            </Tabs>
          </template>
          <template v-if="currentTabIndex===3">
            <Tabs v-model="currentIgniteTabIndex"
                  class="tabs"
                  key="child4">
              <TabPane v-for="item in igniteTabArr"
                       :key="item.name"
                       :label="item.name"></TabPane>
            </Tabs>
          </template> -->
          <!-- <Dropdown>
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="part">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('part')">
                  <Button>
                    导出部分
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> -->
        </div>
        <div class="table"
             :class="currentTabIndex===1?'p-t-15':''">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="getData"
                 border
                 stripe
                 :loading="tableLoading"
                 :row-class-name="rowClassName"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { mapActions, mapGetters } from 'vuex';
import { getCurrentYearToNow, getToday, getYesterday, getNearlyThreeDay, getCurrentMonth, getLastMonth, getYearOrMonthForMonth, getNearlySevenDay, getPreMonthDay } from '@/utils/dateRangUtil'
import ExportTemplate from '@/components/common/ExportTemplate'
import { throttle } from '@/utils/debounceAndThrottle';
import Monthrange from '@/components/common/Monthrange'
export default {
  components: { Echarts, MyTreeSelect, ExportTemplate, Monthrange },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date > new Date()
        }
      },
      carViolationsEchartsData: [], // 行驶里程echarts
      personViolationsEchartsData: [],        // 人员 echarts
      parkingEchartsData: [],        // 停车 echarts
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '车辆违规'
        },
        // {
        //   name: '里程报表'
        // },
        {
          name: '人员违规'
        },
        // {
        //   name: '停车报表'
        // },
        // {
        //   name: '点火报表'
        // },
      ],
      carNumber: '',
      carNumberId: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      departmentId: '',
      departmentName: '',
      alarmType: '',
      alarmTypeArr: '',
      personName: '',
      personNameId: '',
      remoteName: '',
      remoteNameList: [],
      remoteNameLoading: false,
      carViolateType: '',
      carViolateTypeArr: [],
      personViolateType: '',
      personViolateTypeArr: [],
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      carType: '',
      carTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '巡查车'
        },
        {
          id: 2,
          name: '洒水车'
        },
        {
          id: 3,
          name: '转运车'
        },
      ],
      stayMinute: '',
      parkingMinute: '',
      departmentName: '',
      date: [],
      dateMonthrange: [],
      dateMonth: '',
      currentRunEchartsTabIndex: 0,
      runEchartsTabArr: [
        {
          name: '时间走势'
        },
      ],
      currentRunTabIndex: 0,
      runTabArr: [
        {
          name: '违规汇总'
        },
        {
          name: '违规明细'
        },
      ],
      currentStayEchartsTabIndex: 0,
      stayEchartsTabArr: [
        {
          name: '时间走势'
        },
      ],
      currentStayTabIndex: 0,
      stayTabArr: [
        {
          name: '违规汇总'
        },
        {
          name: '违规明细'
        },
      ],
      currentParkEchartsTabIndex: 0,
      parkEchartsTabArr: [
        {
          name: '车辆停车报表'
        },
      ],
      currentParkTabIndex: 0,
      parkTabArr: [
        {
          name: '停车汇总'
        },
        {
          name: '停车明细'
        },
      ],
      currentIgniteEchartsTabIndex: 0,
      igniteEchartsTabArr: [
        {
          name: '车辆点火报表'
        },
      ],
      currentIgniteTabIndex: 0,
      igniteTabArr: [
        {
          name: '点火汇总'
        },
        {
          name: '点火明细'
        },
      ],
      totalOptions: {
        dataset: {
          source: []
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          name: '单位：次'
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderWidth: '1',
          borderColor: 'gray',
          textStyle: {
            color: 'black'
          },
          formatter: '',
        },
        series: [{
          type: 'line',
          // smooth: true,
          barWidth: 30,
        }],
        color: ['#25bb96'],
      },
      // stopOptions: {
      //   dataset: {
      //     source: []
      //   },
      //   xAxis: {
      //     type: 'category',
      //   },
      //   yAxis: {
      //     type: 'value',
      //     splitLine: {
      //       show: false
      //     },
      //     name: '单位：h'
      //   },
      //   tooltip: {
      //     trigger: 'axis'
      //   },
      //   series: [{
      //     type: 'bar',
      //     smooth: true,
      //     barWidth: 30,
      //   }],
      //   color: ['#25bb96'],
      // },
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      // 行程汇总 columns
      dataRunSummary: [],
      columnsRunSummary: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carName',
          tooltip: true,
          fixed: 'left',
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickDrivingMileageDetail(row)
                }
              }
            }, row.carName);
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计违规（次）',
          key: 'allCount',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '空驶',
        //   key: 'clksCount',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },
        // {
        //   title: '停留',
        //   key: 'cltlCount',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },
        // {
        //   title: '离岗',
        //   key: 'cltlCount',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },
        // {
        //   title: '类型3',
        //   key: 'resList',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },
        // {
        //   title: '类型4',
        //   key: 'resList',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },
        // {
        //   title: '类型5',
        //   key: 'resList',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },
      ],
      dataRunDetail: [],
      columnsRunDetail: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规类型',
          key: 'ruleTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '发生地点',
          key: 'address',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始时间',
          key: 'violationStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束时间',
          key: 'violationEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '具体内容',
          key: 'content',
          tooltip: true,
          align: 'center'
        },
      ],
      // 里程 columns
      // dataMileage: [],
      // columnsMileage: [
      //   {
      //     type: 'selection',
      //     width: 45,
      //     align: 'center'
      //   },
      //   {
      //     title: '序号',
      //     width: 45,
      //     align: 'center',
      //     render: (h, params) => {
      //       let index = params.index + 1
      //       let num = (this.pageNum - 1) * this.pageSize + index
      //       return h('span', num)
      //     }
      //   },
      //   {
      //     title: '车牌号',
      //     key: 'carnumber',
      //     tooltip: true,
      //     align: 'center',
      //     render: (h, params) => {
      //       let row = params.row
      //       return h('span', {
      //         style: {
      //           color: '#25bb96',
      //           cursor: 'pointer'
      //         },
      //         on: {
      //           click: () => {
      //             this.onClickDrivingMileageDetail(row)
      //           }
      //         }
      //       }, row.carnumber);
      //     }
      //   },
      //   {
      //     title: '车辆类型',
      //     key: 'carTypeName',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '所属机构',
      //     key: 'deptidforcarName',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '开始时间',
      //     key: 'startDate',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '结束时间',
      //     key: 'endDate',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '行驶时长',
      //     key: 'runTimeLength',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '行驶里程(km)',
      //     key: 'clockMileage',
      //     tooltip: true,
      //     align: 'center',
      //     render: (h, params) => {
      //       return h('span', {}, params.row.clockMileage.toFixed(2));
      //     }
      //   },
      //   {
      //     title: '开始位置',
      //     key: 'startPosition',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '结束位置',
      //     key: 'endPosition',
      //     tooltip: true,
      //     align: 'center'
      //   },
      // ],
      // 停留汇总 columns
      dataStaySummary: [],
      columnsStaySummary: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'personName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickPersonAlarmDetail(row)
                }
              }
            }, row.personName);
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计违规（次）',
          key: 'allCount',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '离岗',
        //   key: 'allCount',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },
        // {
        //   title: '停留',
        //   key: 'allCount',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },
        // {
        //   title: '聚众',
        //   key: 'allCount',
        //   tooltip: true,
        //   align: 'center',
        //   sortable: true
        // },

      ],
      dataStayDetail: [],
      columnsStayDetail: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'personName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规类型',
          key: 'violationTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '发生地点',
          key: 'address',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始时间',
          key: 'violationStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束时间',
          key: 'violationEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '具体内容',
          key: 'content',
          tooltip: true,
          align: 'center'
        },
      ],
      // 停车汇总 columns
      // dataParkSummary: [],
      // columnsParkSummary: [
      //   {
      //     title: '序号',
      //     width: 45,
      //     align: 'center',
      //     render: (h, params) => {
      //       let index = params.index + 1
      //       let num = (this.pageNum - 1) * this.pageSize + index
      //       return h('span', num)
      //     }
      //   },
      //   {
      //     title: '车牌号',
      //     key: 'manageName',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '车辆类型',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '所属机构',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '累计停车次数',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '停车时长',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      // ],
      // dataParkDetail: [],
      // columnsParkDetail: [
      //   {
      //     title: '序号',
      //     width: 45,
      //     align: 'center',
      //     render: (h, params) => {
      //       let index = params.index + 1
      //       let num = (this.pageNum - 1) * this.pageSize + index
      //       return h('span', num)
      //     }
      //   },
      //   {
      //     title: '车牌号',
      //     key: 'manageName',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '车辆类型',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '所属机构',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '累计停车次数',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '停车时长',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      // ],
      // 点火汇总 columns
      // dataIgniteSummary: [],
      // columnsIgniteSummary: [
      //   {
      //     title: '序号',
      //     width: 45,
      //     align: 'center',
      //     render: (h, params) => {
      //       let index = params.index + 1
      //       let num = (this.pageNum - 1) * this.pageSize + index
      //       return h('span', num)
      //     }
      //   },
      //   {
      //     title: '车牌号',
      //     key: 'manageName',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '车辆类型',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '所属机构',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '点火次数',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '点火时长',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      // ],
      // dataIgniteDetail: [],
      // columnsIgniteDetail: [
      //   {
      //     title: '序号',
      //     width: 45,
      //     align: 'center',
      //     render: (h, params) => {
      //       let index = params.index + 1
      //       let num = (this.pageNum - 1) * this.pageSize + index
      //       return h('span', num)
      //     }
      //   },
      //   {
      //     title: '车牌号',
      //     key: 'manageName',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '车辆类型',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '所属机构',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '点火次数',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      //   {
      //     title: '点火时长',
      //     key: 'managePhone',
      //     tooltip: true,
      //     align: 'center'
      //   },
      // ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      isSummaryClick: false
    };
  },
  watch: {
    selectType (newVal) {
      if (newVal == 1) {
        this.onClickCurrentMonth()
      } else {
        this.onClickCurrentYear()
      }
    },
    selectArr (newVal) {
      console.log(newVal);
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.getData.find((items, index) => {
            return item.carId == items.carId
          })
          this.selectIndexArr.push(data.carId)
        })
      }
    },
    currentStayTabIndex (newVal) {
      this.selectArr = []
      if (newVal == 0) {
        // 如果是点击汇总列表中车牌号跳转的 则清除车辆数据并置为false
        if (this.isSummaryClick) {
          this.personName = ''
          this.personNameId = ''
          this.remoteName = ''
          this.isSummaryClick = false
        }
      }
      this.onClickSearch()
    },
    currentRunTabIndex (newVal) {
      this.selectArr = []
      if (newVal == 0) {
        // 如果是点击汇总列表中车牌号跳转的 则清除车辆数据并置为false
        if (this.isSummaryClick) {
          this.carNumber = ''
          this.carNumberId = ''
          this.remoteCar = ''
          this.isSummaryClick = false
        }
      }
      this.onClickSearch()
    },
  },
  computed: {
    ...mapGetters({
      'typeArr': 'getCarTypeAllList',
      'carTreeArr': 'getCarTreeList',
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentRunTabIndex) {
            case 0:
              columns = this.columnsRunSummary
              break;
            case 1:
              columns = this.columnsRunDetail
              break;
            default:
              break;
          }
          break;
        // case 1:
        //   columns = this.columnsMileage
        //   break;
        case 1:
          switch (this.currentStayTabIndex) {
            case 0:
              columns = this.columnsStaySummary
              break;
            case 1:
              columns = this.columnsStayDetail
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentParkTabIndex) {
            case 0:
              columns = this.columnsParkSummary
              break;
            case 1:
              columns = this.columnsParkDetail
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (this.currentIgniteTabIndex) {
            case 0:
              columns = this.columnsIgniteSummary
              break;
            case 1:
              columns = this.columnsIgniteDetail
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getData () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentRunTabIndex) {
            case 0:
              columns = this.dataRunSummary
              break;
            case 1:
              columns = this.dataRunDetail
              break;
            default:
              break;
          }
          break;
        // case 1:
        //   columns = this.dataMileage
        //   break;
        case 1:
          switch (this.currentStayTabIndex) {
            case 0:
              columns = this.dataStaySummary
              break;
            case 1:
              columns = this.dataStayDetail
              break;
            default:
              break;
          }
          break;
        // case 2:
        //   switch (this.currentParkTabIndex) {
        //     case 0:
        //       columns = this.dataParkSummary
        //       break;
        //     case 1:
        //       columns = this.dataParkDetail
        //       break;
        //     default:
        //       break;
        //   }
        //   break;
        // case 3:
        //   switch (this.currentIgniteTabIndex) {
        //     case 0:
        //       columns = this.dataIgniteSummary
        //       break;
        //     case 1:
        //       columns = this.dataIgniteDetail
        //       break;
        //     default:
        //       break;
        //   }
        //   break;
        default:
          break;
      }
      return columns
    },
    typeArr () {
      let arr = [{
        id: '0',
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getCarTypeAllList)
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportDrivingMileage
          break;
        case 1:
          path = this.$http.exportStay
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    ...mapActions([
      'updateCarTypeAllList',
      'updateCarTreeList',
      'updatePersonTreeList',
      'updateDepartmentTreeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateCarTreeList()
      this.updatePersonTreeList()
      let arr = [{
        id: 0,
        name: '全部'
      }]

      this.$http.getWorkRuleItemList({ type: 3 }).then(res => {
        if (res.code === 200) {
          this.carViolateTypeArr = arr.concat(res.result.map(item => {
            item.id = item.sysName
            item.name = item.sysName
            return item
          }))
        }
      })
      this.$http.getWorkRuleItemList({ type: 2 }).then(res => {
        if (res.code === 200) {
          this.personViolateTypeArr = arr.concat(res.result.map(item => {
            item.id = item.sysName
            item.name = item.sysName
            return item
          }))
        }
      })
      if (this.$route.params.authorType) {
        let params = this.$route.params
        if (params.authorType === 'car') {
          this.violationId = params.id
          this.carViolateType = params.type
          this.carNumberId = params.authorId
          this.carNumber = params.author
        } else {
          this.currentTabIndex = 1
          this.violationId = params.id
          this.personViolateType = params.type
          this.personId = params.authorId
          this.personName = params.author
        }
      }
      this.getList()
    },
    // 行驶里程报表数据
    getCarViolationsData () {
      let params = {
        carId: this.remoteCar,  // 车牌号
        // carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        depId: this.departmentId,
        // carName:this.remoteCar,
        ruleTypeName: this.carViolateType == 0 ? '' : this.carViolateType,
        violationStartTime: this.date.length == 0 ? '' : this.date[0],
        violationEndTime: this.date.length == 0 ? '' : this.date[1],

      }
      console.log("1111111", params);
      // this.dateJudge(params)
      this.getCarViolationsEchars(params)
      switch (this.currentRunTabIndex) {
        case 0:
          this.getCarViolationsList(params)
          break;
        case 1:
          this.getCarViolationsDetail(params)
          break;
        default:
          break;
      }
    },
    getCarViolationsEchars (params) {
      // 天1 月2
      params.dateType = this.selectType
      this.carViolationsEchartsData = []
      this.$http.getCarViolationsEchars(params).then(res => {
        if (res.code == 200) {
          this.carViolationsEchartsData = res.result
        }
      })
    },
    // // 行驶里程 echars 
    // getCarViolationsEchars (params) {
    //   this.$http.getCarViolationsEchars(params).then(res => {
    //     if (res.code == 200) {
    //       const data = res.result
    //       this.carViolationsEchartsData = data
    //     }
    //   })
    // },
    // 行驶里程列表数据
    getCarViolationsList (params) {
      params.page = this.pageNum
      params.size = this.pageSize
      params.isEve = 0
      this.listData('getCarViolationsList', params, 'dataRunSummary')
    },
    getCarViolationsDetail (params) {
      params.pageNum = this.pageNum
      params.pageSize = this.pageSize
      params.isEve = 1
      this.listData('getCarViolationsDetail', params, 'dataRunDetail')
    },
    // 行驶里程列表点击切换
    onClickDrivingMileageDetail (row) {
      this.isSummaryClick = true
      this.remoteCar = row.carId
      this.carNumberId = row.carId
      this.carNumber = row.carnumber
      this.currentRunTabIndex = 1
    },
    // 里程报表数据
    getMileageData () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        isEve: 0,
        carId: this.remoteCar,  // 车牌号
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        depId: this.departmentId
      }
      this.dateJudge(params)
      this.getMileageList(params)
    },
    // 里程报表列表数据
    getMileageList (params) {
      this.listData('getCarViolationsList', params, 'dataMileage')
    },
    // 人员报表数据
    getPersonViolationsData () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        depId: this.departmentId,
        personId: this.remoteName,
        violationTypeName: this.personViolateType == 0 ? '' : this.personViolateType,
        violationStartTime: this.date.length == 0 ? '' : this.date[0],
        violationEndTime: this.date.length == 0 ? '' : this.date[1],

      }
      // this.dateJudge(params)
      console.log(params);
      console.log(this.date);
      this.getPersonViolationsEachars(params)
      switch (this.currentStayTabIndex) {
        case 0:
          this.getPersonViolationsList(params)
          break;
        case 1:
          this.getPersonViolationsDetail(params)
          break;
        default:
          break;
      }
    },
    // getPersonViolationsEachars (params) {
    //   this.$http.getPersonViolationsEachars(params).then(res => {
    //     if (res.code == 200) {
    //       const data = res.result
    //       this.personViolationsEchartsData = data
    //     }
    //   })
    // },
    // 人员报警 报警分析 echars 数据
    getPersonViolationsEachars (params) {
      // 天1 月2
      params.dateType = this.selectType
      this.$http.getPersonViolationsEachars(params).then(res => {
        if (res.code == 200) {
          this.personViolationsEchartsData = res.result
        }
      })
    },
    //       this.totalOptions.tooltip.formatter = e => {
    //         return `
    //           <div >
    //             ${e[0].data[0]}<br />
    //             全部: ${e[0].data[1]}<br />
    //             <span style="color:#ff1a1a">离线: </span> ${e[0].data[2]}<br />
    //             <span style="color:#fe9400">SOS求救: </span> ${e[0].data[3]}<br />
    //           </div>
    //         `
    //       }
    //     }
    //   })
    // },
    // 人员报表列表数据
    getPersonViolationsList (params) {
      this.$http.getPersonViolationsList(params).then(res => {
        this.tableLoading = true
        if (res.code == 200) {
          this.dataStaySummary = res.result.rows
          this.totalPage = res.result.total
          this.tableLoading = false
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 人员报表详情列表数据
    getPersonViolationsDetail (params) {
      this.$http.getPersonViolationsDetail(params).then(res => {
        this.tableLoading = true
        if (res.code == 200) {
          this.dataStayDetail = res.result.rows
          this.totalPage = res.result.total
          this.tableLoading = false
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 人员报表列表点击车牌号切换
    // onClickStayDetail (row) {
    //   this.isSummaryClick = true
    //   this.remoteCar = row.carId
    //   this.carNumberId = row.carId
    //   this.carNumber = row.carnumber
    //   this.currentStayTabIndex = 1
    // },

    onClickPersonAlarmDetail (row) {
      this.nameId = row.personId
      this.name = row.personName
      this.remoteName = row.personId
      this.currentStayTabIndex = 1
    },
    // 列表
    async listData (api, params, data) {
      this.tableLoading = true
      let res = {}
      try {
        res = await this.$http[api](params)
        if (res.code == 200) {
          this.tableLoading = false
          this[data] = res.result.rows
          this.totalPage = res.result.total
        }
      } catch (err) {
        this.tableLoading = false
        console.log(err);
      }
      return res
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 日期格式化
    dateJudge (params) {
      if (this.selectType == 1) {
        // 天
        if (this.date[0]) {
          params.violationStartTime = this.date[0]
          params.violationEndTime = this.date[1]
        }
      } else {
        // 月
        if (this.dateMonth) {
          let arr = this.dateMonth.split('-')
          let month = getYearOrMonthForMonth(arr[0], arr[1])
          params.violationStartTime = month[0]
          params.violationEndTime = month[1]
        }
      }
      return params
    },
    // 修改车牌号
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    onChangeDate (date) {
      // this.date = date
      let millisecond = 1000 * 60 * 60 * 24
      let startDate = new Date(date[0]).getTime()
      let endDate = new Date(date[1]).getTime()
      if (endDate - startDate < millisecond * 2) {
        this.date = getCurrentMonth()
        this.$Message.info('最少选择三天')
      } else {
        this.date = date
      }
      this.buttonIndex = null
    },
    // 月份回调
    onChangeMonthrange (arr) {
      let startMonthArr = arr.month[0].split('-')
      let endMonthArr = arr.month[1].split('-')
      if (parseInt(endMonthArr[1]) - parseInt(startMonthArr[1]) >= 2) {
        this.date = arr.day
      } else {
        this.date = []
        this.dateMonthrange = []
        this.$Message.info('最少选择三个月')
      }
      this.buttonIndex = null
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.carId)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.carId)) {
        return 'table-bgcolor'
      }
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay(false)
      this.buttonIndex = 2
      this.getList()
    },
    // 近七天
    onClickNearlySevenDay () {
      this.date = getNearlySevenDay(false)
      this.getList()
      this.buttonIndex = 0
    },
    // 昨天
    onClickYesterday () {
      this.date = [getYesterday(), getYesterday()]
      this.buttonIndex = 1
      this.getList()
    },
    // 今天
    onClickToday () {
      this.date = [getToday(), getToday()]
      this.buttonIndex = 0
      this.getList()
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth(false)
      let time = getCurrentMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.buttonIndex = 3
      this.getList()
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      let time = getLastMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.buttonIndex = 4
      this.getList()
    },
    // 近几个月
    onClickPreMonthDay (num) {
      this.date = getPreMonthDay(num)
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
      switch (num) {
        case 3:
          this.buttonIndex = 3
          break;
        case 6:
          this.buttonIndex = 4
          break;
        default:
          break;
      }
    },
    // 本年
    onClickCurrentYear () {
      this.date = getCurrentYearToNow()
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 重置按钮
    onResetSearchData () {
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    remoteNameMethod: throttle(function (query) {
      if (query !== '') {
        this.remoteNameLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchPersonList(params).then((res) => {
          if (res.code === 200) {
            this.remoteNameLoading = false
            let data = res.result
            this.remoteNameList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteNameLoading = false
            this.remoteNameList = [];
          }
        })
      } else {
        this.remoteNameList = [];
      }
    }),
    // 点击切换tab
    onClickTabs () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // // tabs 标签页切换
    // onClickTabs (data) {
    //   this.selectArr = []
    //   this.resetSearchData()
    //   if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
    //     this.onClickNearlyThreeDay()
    //   } else {
    //     this.onClickCurrentMonth()
    //   }
    // },
    // 获取数据
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getCarViolationsData()
          break;
        // case 1:
        //   this.getMileageData()
        //   break;
        case 1:
          this.getPersonViolationsData()
          break;
        case 3:
          // this.getParkingData()
          break;
        default:
          break;
      }
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = [];
          }
        })
      } else {
        this.remoteCarList = [];
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    onQueryChangeRemoteName (value) {
      if (!value) {
        this.remoteNameList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.pageSize = 20
      // this.getFuelConsumptionList()
      this.getList()
    },
    // 重置搜索条件
    resetSearchData () {
      this.pageNum = 1
      this.pageSize = 20
      this.carType = ''
      this.selectType = 1
      this.departmentId = ''
      this.departmentName = ''
      this.carViolateType = ''
      this.carNumber = ''
      this.carNumberId = ''
      this.name = ''
      this.nameId = ''
      this.remoteName = ''
      this.personAlarmType = ''
      this.personName = ''
      this.personId = ''
      this.personViolateType = ''
      this.remoteCar = ''
      this.parkingMinute = ''
      this.stayMinute = ''
      this.date = []
      this.dateMonth = ''
      if (this.$refs.formDate) {
        this.$refs.formDate.handleClear()
      }
      this.buttonIndex = null
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            isEve: this.currentRunTabIndex,
            carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
          }
          this.dateJudge(data)
          switch (value) {
            case "now":
              if (this.currentRunTabIndex == 0) {
                data.carIds = this.getData.map(item => {
                  return item.carId
                })
              } else {
                data.carId = this.remoteCar
                data.times = this.getData.map(item => {
                  return item.dateString
                })
              }
              break;
            case "part":
              if (this.currentRunTabIndex == 0) {
                data.carIds = this.selectArr.map(item => {
                  return item.carId
                })
              } else {
                data.carId = this.remoteCar
                data.times = this.selectArr.map(item => {
                  return item.dateString
                })
              }
              break;
            case "all":
              if (this.currentRunTabIndex == 1) {
                data.carId = this.remoteCar
              }
              break;
            default:
              break;
          }
          break;
        case 1:
          data = {
            isEve: this.currentStayTabIndex,
            carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
          }
          this.dateJudge(data)
          switch (value) {
            case "now":
              if (this.currentStayTabIndex == 0) {
                data.carIds = this.getData.map(item => {
                  return item.carId
                })
              } else {
                data.carId = this.remoteCar
                data.times = this.getData.map(item => {
                  return item.stayStartTime + ',' + item.stayEndTime
                })
              }
              break;
            case "part":
              if (this.currentStayTabIndex == 0) {
                data.carIds = this.selectArr.map(item => {
                  return item.carId
                })
              } else {
                data.carId = this.remoteCar
                data.times = this.selectArr.map(item => {
                  return item.stayStartTime + ',' + item.stayEndTime
                })
              }
              break;
            case "all":
              if (this.currentStayTabIndex == 1) {
                data.carId = this.remoteCar
              }
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.noData {
  height: 400px;
  font-size: 16px;
  img {
    width: 150px;
    height: 100px;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }
  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;
      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;
        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }
      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>